
.info-container{
    width: 100%;
    min-height: 43vh;
    background: #fff;
    border-radius: 6px;
    padding: 20px 60px 30px 40px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: 18px 0;
  }
  .info-container h4{
    letter-spacing: 2px;
    font-size: 1.2vw;
    text-align: left;
    }
    .info-container p{
        font-size: 1vw;
        font-weight:600;
        line-height: 2;
        /* margin-right: 40px; */
        text-align: left;
    }
    .info-container .info-content{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    
    
  .info-container .info-content .left-side{
    width: 25%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: justify;
    margin-top: 15px;
    position: relative;
  }
  
  .info-content .left-side::before{
    content: '';
    position: absolute;
    height: 70%;
    width: 2px;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    background: #afafb6;
  }
  .info-content .left-side .details{
    /* margin: 14px 0; */
    text-align: center;
  }
  .info-content .left-side .details i{
    font-size: 30px;
    color: #3e2093;
    margin-bottom: 10px;
  }
  .info-content .left-side .details .topic{
    font-size: 18px;
    font-weight: 500;
  }
  .info-content .left-side .details .text-one,
  .info-content .left-side .details .text-two{
    font-size: 14px;
    color: #afafb6;
  }
  .info-container .info-content .right-side{
    width: 75%;
    margin-left: 54px;
   
  }
  .info-content .right-side .topic-text{
    font-size: 23px;
    font-weight: 600;
    color: #333;
  }
  .right-side .input-box{
    height: 50px;
    width: 100%;
    margin: 12px 0;
  }
  .row-2{
    display: none;
  }
  
  .right-side .input-box input,
  .right-side .input-box textarea{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    background: #F0F1F8;
    border-radius: 6px;
    padding: 0px 15px;
    resize: none;
  }
  .right-side .message-box{
    min-height: 110px;
  }
  .right-side .input-box textarea{
    padding-top: 6px;
  }
  .right-side .button{
    display: inline-block;
    margin-top: 12px;
  }
  
  .right-side .button input[type="button"]{
    color: #fff;
    font-size: 18px;
    outline: none;
    border: none;
    padding: 8px 16px;
    border-radius: 6px;
    background: aquamarine;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .button input[type="button"]:hover{
    background: aquamarine;
  }
  form input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #333;
    padding: 10px 5px;
    outline: none;
    }
    form textarea{
        width: 100%;
        border: none;
        border-bottom: 1px solid #333;
        padding: 10px 5px;
        outline: none;
    }
    #name{
        display: flex;
        justify-content: space-between;
        outline: none;
    }
    .input-row .input-group{
        flex-basis: 47%;
    }
    
    ::placeholder{
        font-size: 15px;
        color: #1d1c1c;
    }
    .info-container button{
      background: #fff;
      padding: 12px 8px;
      border: none;
      outline: none;
      color: #1d1c1c;
      text-align: center;
      font-weight: 600;
      margin-top: 13px;
      cursor: pointer;
      letter-spacing: 1px;
      transition: 0.5s;
  }
  .info-container button:hover{
      color: rgb(197, 194, 194);
  }
  .social-link{
    color: rgb(112, 112, 112);
    
  }
  .social-link a{
    color: #00a169;
    transition: 0.25s;
  }
  .social-link a:hover{
    color: aquamarine;
  }
  
  @media (max-width: 950px) {
    .info-container{
      width: 100%;
      padding: 30px 40px 40px 35px ;
    }
    .info-container .info-content .right-side{
     width: 75%;
     margin-left: 55px;
    }
    
  }
  @media (max-width: 820px) {
    .info-container{
      margin: 40px 0;
      height: 100%;
    }
    .info-container .info-content{
      flex-direction: column-reverse;
    }
   .info-container .info-content {
     width: 100%;
     flex-direction: row;
     margin-top: 40px;
     
     justify-content: center;
     flex-wrap: wrap;
 
   }
   .info-container .info-content .left-side::before{
     display: none;
   }
    .details{
      display: flex;
      flex-direction: column;
      

    }
    .social-link{
      text-align: center;
    }
   .info-container .info-content .right-side{
     width: 100%;
     margin-left: 0;
   }
   .info-container p{
    font-size: 0.9em;
    min-width: 10vw;
    text-align: center;
   }
   .info-container h4{
    font-size: 2em;
    min-width: 210px;
    text-align: center;
  }
   .row-2{
    display: flex;
    flex-direction: column;
  }
  .col-2{
    flex: 50%;
    padding: 20px;
    
  }
  .row-1{
    display: none;
  }
}

@media screen and (max-width: 535px){
  .info-container h4{
    font-size: 1.4em;
    min-width: 250px;
  }
}
  

