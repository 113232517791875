.about{
    margin: 12px 0px;
    padding: 25px 140px;
    background-color: #fff;
    width: 100%;
    min-height: 52vh;
    text-align: center;
    /* height: 130vh; */
}
.about h4{
    font-size: 25px;
    margin: 90px 0 40px 0;
}
.about p{
    /* font-size: 14px; */
    line-height: 2;
    letter-spacing: 0.7px;
    font-size: 15px;
    padding-bottom: 34px;
}

@media (426px <= width <= 768px){
    .about{
        padding: 25px 70px;
    }
    .about h4{
        font-size: 3.45vw;
    }
}
@media (width <= 426px){
    .about{
        padding: 25px 43px;
    }
    .about p{
        font-size:16px;
    }
}