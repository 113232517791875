

.social-icons{
    
    /* display: grid; */
    display: grid;
    gap: 22px;
    position: fixed;
    top: 50%;
    right: 32px;
    transition: 0.2s;
    
}
.social-icons a{
    color: #333;
}
.social-icons a:hover{
    color: #c4b7a2;
}
@media screen and (max-width: 535px){
    .social-icons{
        display: flex;
        /* position: absolute; */
        top: 0;
        right: 0;
        position: static;
        justify-content: center;
        margin: 40px;
    }
}