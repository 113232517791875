
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300&family=Poppins:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Figtree', sans-serif;
    
}

nav{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 58px;
    z-index: 100;
    background-color: #fbfbfb;
    
}

nav .logo{
    position: relative;
    font-weight: 430;
    color: #070606;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    text-decoration: none;
}

nav ul{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

nav ul li{
    position: relative;
    list-style: none;
    
}

nav ul li a{
    position: relative;
    margin: 0 18px;
    text-decoration: none;
    color: #000000;
    letter-spacing: 1px;
    font-weight: 200px;
    font-size: 0.9em;
    transition: 0.3s;
    /* color: black; */ 
}

nav ul li a:hover{
    color: #c4b7a2;
}

nav .menu-btn i{
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    display: none;
    
}
input[type="checkbox"]{
    display: none;
}

@media (max-width: 920px) {
    
    nav .menu-btn i{
      display: block;
      margin-left: 10px;
      color: #333;
    }
    #click:checked ~ .menu-btn i:before{
      content: "\f00d";
    }
    nav{
        padding: 25px 32px 25px 50px;
    }
    nav ul{
      position: fixed;
      top: 72px;
      left: -100%;
      background: rgb(255, 255, 255);
      
      height: 100vh;
      width: 100%;
      text-align: center;
      display: block;
      transition: all 0.3s ease;
    }
    #click:checked ~ ul{
      left: 0;
    }
    nav ul li{
      width: 100%;
      margin: 40px 0;
    }
    nav ul li a{
      width: 100%;
      margin-left: -100%;
      display: block;
      font-size: 20px;
      /* transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
      color: #070606;
    }
    #click:checked ~ ul li a{
      margin-left: 0px;
    }
    
    nav ul li a:hover{
      background: none;
      color: #c4b7a2;
    }
}
