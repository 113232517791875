

.grid-container{
    position: relative;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-flow: row;
    margin: 18px 0px;
    align-items: center;
    justify-content: center;
    gap: 13px;
}
.grid-item{
    width: 100%;
    min-height: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    /* border: 1px solid #333; */
    text-align: center;
    margin: 20px 0;
    cursor: pointer;
}
.grid-item h2{
    font-size: 14px;
    margin-top: 18px;
    color: #333;
    font-weight: 700;
}
.grid-item p{
    font-size: 12px;
    margin-top: 12px;
    color: #333;
    font-weight: 500;
}
.grid-item img{
    object-position: center;
    object-fit: cover;
    max-height: 210px;
    width: 100%;
}




#button-cart{
    padding: 14px 100px;
    text-decoration: none;
    border: none;
    color: #343434;
    background-color: #fff;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 0.43px;
    margin-top: 22px;
    transition: 0.3s;
    
}

#button-cart:hover{
    background-color: #e7e7e7;
    opacity: 0.9;
}

@media (120px <= width <= 900px){
    .grid-container{
        grid-template-columns: repeat(1, 1fr);
    }
    .grid-item h2{
        font-size: 22px;
    }
    .grid-item p{
        font-size: 18px;
    }
    #button-cart{
        font-size: 16px;
    }
}