.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html{
  scroll-behavior: smooth;
}

body{
  /* background-image: linear-gradient(to bottom , #009590 50%, rgb(43, 43, 43)); */
  background-image: linear-gradient(to bottom , #ffe8d6 50%, rgb(247, 247, 247));
  margin: 0 40px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* @media (max-width = 920px){
  body{
    margin: ;
  }
}  */

@media screen and (max-width: 535px) {
  body {
    margin: 0 auto;
  }
}