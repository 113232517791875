.modal_8{
    position: fixed;
    width: 80vw;
    height: 84vh;
    bottom: 50%;
    right: 48%;
    transform: translate(50%, 50%);
    /* display: block;
    justify-content: center;
    align-items: center; */
    background-color: #fff;
    padding: 36px;
    z-index: 1000;
    cursor:default;
    margin-bottom: 10px;
}
.modal_8-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.14);
    z-index: 1000;
    cursor: default;
}
.modal_8-info{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding-top: 22px;
}

.modal_8-info p{
    padding: 0 72px;
    font-size: 1.2vw;
    color: #333;
}
.modal_8-info h2{
    font-weight: bold;
    color: black;
    font-size: 18px;
}

/* .col{
    display: flex;
    flex-direction: column;
    text-align: left;
} */


.modal_8-info img{
    
    height: 37vh;
    max-width: 22em;

}

.modal_8 button{
    /* display: flex;
    justify-content: space-between; */
    padding: 10px;
    float: right;
    border: none;
    outline: none;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 912px){
    .modal_8-info img{
        height: 34vh;
        width: 37vw;
    }

}
@media (max-width: 618px){
    .modal_8-info img{
        height: 15vh;
        width: 50vw;
    }
    .modal_8-info p{
        padding: 20px 0px;
        font-size: 2.1vw;
    }
    .modal_8 button{
        margin-left: 20px;
        height: auto;
        min-width: 10px;
        
    }
    .modal_8{
        padding: 10px;
        padding-left: 58px;
        height: 65vh;
        width: 80vw;
    }
}
@media (max-width: 426px){
    .modal_8-info{
        padding-top: 34px;
    }
    .modal_8-info img{
        height: 30vh;
        width: 50vw;
    }
    .modal_8-info p{
        font-size: 16px;
        padding: 0px;
    }
    .modal_8-info h2{
        font-size: 18px;
        margin: 2px 0px;
    }

}


