@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300&family=Poppins:wght@300&display=swap');

*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Figtree', sans-serif;
    
}


.container {
    position: relative;
    text-align: center;
    color: white;
    background-color: #274a68;
    /* height: 70vh; */

}

.container img{
    margin-top: 70px;
    margin-bottom: -4px;
    background-color: black;
    opacity: 0.7;
    height: 75vh;
    object-fit: cover;
}

img{
    width: 100%;
    min-height: 100%;
    
    background-color: black;
    /* opacity: 0.7; */
}

.centered {
    position: absolute;
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100vw;
    
}

.centered h2{
    font-size: 3.4vmax;
}
.centered p{
    font-size: 2.2vw;
    margin-top: 12px;
    margin-bottom: 34px;
}

a{
    text-decoration: none;
}

.button-shop-now{
    padding: 18px 70px;
    text-decoration: none;
    color:#fff;
    background-color: #333;
    font-weight: 850;
    cursor: pointer;
    border: none;
    transition: 0.4s;
    font-size: 14px;
}
.button-shop-now:hover{
    background-color: #c2a989;

}

.collection{
    margin: 12px 0px;
    padding: 25px 58px;
    /* background-color: #e4f6ff; */
    background-color: #fff;
    width: 100%;
    min-height: 52vh;
    text-align: center;
    color: #333;
}
.collection h4{
    font-size: 25px;
    margin: 52px 0 40px 0;
}
.collection p{
    font-size: 18px;
    line-height: 2;
    letter-spacing: 0.7px;
    
}

@media (width <= 769px){
    .centered h2{
        font-size: 4vw;
    }
    .centered p{
        font-size: 2.5vw;
    }
    
} 
@media screen and (max-width: 535px){
    .centered h2{
        font-size: 10vw;
        width: 80vw;
    }
    .centered p{
        font-size: 7vw;
    }
    .button-shop-now{
        padding: 16px 43px;
    }
}

